<template>
    <div class="icon" :class="'icon'+props.CSStype">
        {{ props.title }}
    </div>
</template>
<script setup>
import { reactive, ref, unref, nextTick, defineEmits } from 'vue'
import { getcontractStatus } from "@/utils/server/getcode.js"
import qs from "qs";
const props = defineProps({
    CSStype:{
        type: Number,
        default:0
    },
    title:{
        type: String,
        default:'认证成功'
    }
})
const emit = defineEmits(['Emit'])
const state = reactive({
})
defineExpose({
    
});
</script>
<style lang="scss" scoped >
@import "@/styles/general/layout/labelbox.scss";
@import "@/styles/color/value.scss";
.icon{
    padding: 5px;
    background: $active-alpha;
    border-radius: 2px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: $active-theme;
}
.icon1{
    color: #1890FF;
    background: rgba(24,144,255,0.1);
}
.icon2{
    color: #F5212D;
    background: rgba(245, 33, 45, 0.1);
}
</style>