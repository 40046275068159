<template>
	<el-drawer custom-class="drawerIconNone" v-model="drawerVisible" title="详情" direction="rtl" size="500px">
		<div class="detailsbox">
			<div class="ovbox">
				<div class="dp-f jc-sb ai-fe mb-25">
					<div class="fs-18">基本信息验证</div>
				</div>
				<div class="labelbox dp-f">
					<div class="label">类型</div>{{ getEqAuthenticationTypeStatus(state.data.authenticationType) }}
				</div>
				<div class="labelbox dp-f">
					<div class="label">	机构/单位全称企业/组织/商户名称</div>{{ state.data.companyName }}
				</div>
				<div class="labelbox dp-f">
					<div class="label">	统一社会信用代码</div>
					{{ state.data.creditCode }}
				</div>
				<div class="labelbox dp-f">
					<div class="label">
						{{ state.data.authenticationType == 0 ? '法定代表人' : state.data.authenticationType == 1 ? '经营者姓名' :
							state.data.authenticationType == 2 ? '法定代表人/负责人姓名' : '' }}
					</div>{{ state.data.juridicalPerson?state.data.juridicalPerson.chnname:'/' }}
				</div>
					<!-- 对公账户验证失败记录 -->
					<template v-for="(el,ind) in state.data.failAccount" :key='ind'>
					<div class="dp-f ai-c mt-50 mb-18">
						<div class="fs-18 mr-10">对公账户验证</div>
						<statusIcon class="ml-7" title="认证失败" CSStype="2"></statusIcon>
					</div>
					<div class="labelbox dp-f">
						<div class="label">对公账户户名</div>{{ el.corporateAccountName }}
					</div>
					<div class="labelbox dp-f">
						<div class="label">对公账户账号</div>{{ el.corporateAccountNo }}
					</div>
					<div class="labelbox dp-f">
						<div class="label">开户行支行</div>{{ el.depositBank }}
					</div>
				</template>
				<template v-if="state.data.authenticationState >= 4 && state.data.corporateAccountNo">
					<div class="dp-f ai-c mt-50 mb-18">
						<div class="fs-18 mr-10">对公账户验证</div>
						<statusIcon v-if="state.data.authenticationState == 4" title="验证中" CSStype="1"></statusIcon>
						<statusIcon v-else-if="state.data.authenticationState >= 5&&state.data.authenticationState <= 8" title="验证成功" CSStype="0"></statusIcon>
					</div>
					<div class="labelbox dp-f">
						<div class="label">对公账户户名</div>{{ state.data.companyName}}
					</div>
					<div class="labelbox dp-f">
						<div class="label">对公账户账号</div>{{ state.data.corporateAccountNo }}
					</div>
					<div class="labelbox dp-f">
						<div class="label">开户行支行</div>{{ state.data.depositBank }}
					</div>
				</template>
				<template v-if="state.data.authenticationState >= 4&&state.data.authenticationState <= 8">
					<!-- <div class="dp-f ai-c mt-50 mb-22" v-if="state.data.remittanceAmount">
						<div class="fs-18 mr-10">回填汇款金额</div>
						{{ state.data.remittanceAmount }}
					</div> -->
					<div class="c-999 mb-42" v-if="state.data.authenticationState == 4">请点击下方“继续认证”填写汇款金额</div>
				</template>
				<!-- 认证授权公函失败记录 -->
				<template v-for="(el,ind) in state.data.failOfficial" :key='ind'>
					<div class="dp-f ai-c mt-50 mb-18">
						<div class="fs-18 mr-10">认证授权公函</div>
						<statusIcon class="ml-7" title="认证失败" CSStype="2"></statusIcon>
					</div>
					<div class="imgbox dp-fc mb-18">
						<img style="max-width: 100%;max-height: 300px;" :src="el.officialFetterFileUrl">
					</div>
				</template>
				<template v-if="state.data.officialFetterFileUrl">
					<div class="dp-f ai-c mt-50 mb-18">
						<div class="fs-18 mr-10">认证授权公函</div>
						<statusIcon class="ml-7" title="审核中" CSStype="1" v-if="state.data.authenticationState == 6"></statusIcon>
						<statusIcon class="ml-7" title="认证成功" CSStype="0" v-else-if="state.data.authenticationState == 7&&state.data.authenticationState == 8"></statusIcon>
					</div>
					<div class="imgbox dp-fc mb-18">
						<img v-if="state.data.officialFetterFileUrl" style="max-width: 100%;max-height: 300px;"
							:src="state.data.officialFetterFileUrl">
					</div>
					<div class="c-999 mb-42" v-if="state.data.authenticationState == 6">如需撤回审核，请点击下方“继续认证”进行操作</div>
				</template>
			</div>
			<div class="fotbox dp-f jc-fe ai-c"
				v-if="state.data.authenticationState != 8 && state.data.authenticationState != 9 && state.data.authenticationState != 10">
				<oabutton class="searcML" width='144' height='40' title="重新发起认证" CSStype=3 @buttonclick="reCertification"/>
				<oabutton class="searcML mr-12" width='106' height='40' title="继续认证" CSStype=2 @buttonclick="Continuation(state.data.authenticationState)"
					v-model:loading="dialogloading"/>
			</div>
		</div>
	</el-drawer>
</template>
<script setup>
import { reactive, ref, unref, nextTick, defineEmits } from 'vue'
import { getauthenticationState, getEqAuthenticationTypeStatus } from "@/utils/server/getcode.js"
import statusIcon from "@/components/layout/parts/statusIcon.vue"
import { handleCofirm, handleMessage } from "@/utils/server/confirm";
import { router_push_name } from "@/utils/server/router";
import { httpToken } from "@/utils/request";
import qs from "qs";
import { useStore } from "vuex";
import { selectById } from "@/utils/server/getdata.js"
const dialogloading = ref(false);// 弹框按钮loading 显示
const dialogVisible = ref(true)//弹框显示
const emit = defineEmits(['reCertification'])
const store = useStore();
const id = ref(0);//操作的id
const drawerVisible = ref(false)//抽屉显示

const state = reactive({
	data: {},//当前展示详情数据
	selectionCount: 0,//选中数量
})
const getDetails = ((id) => {
	// 如果传入记录id重新调取详情接口
	if (id) {
		selectById(id).then(res => {
			state.data = res
			drawerVisible.value = true
		})
	} else {
		state.data = store.state.realName.companyInfo
		drawerVisible.value = true
	}
})
// 重新发起认证
const reCertification = (() => {
	handleCofirm('确认重新发起认证？').then(() => {
		httpToken({
			method: "post",
			url: '/admin/certification/cleanById',
			data: qs.stringify({
				companyId: store.state.realName.companyId,
			}),
		}).then((res) => {
			store.state.realName.companyInfo = null
			drawerVisible.value = false
			emit('reCertification', res)
		})
	}).catch(() => {
		handleMessage('已取消', 'info')
	})
})
// 继续认证
const Continuation = ((statusIcon) => {
	if(statusIcon==1){
		router_push_name('realname_enterprise', { stepIndex: 1 }, false)
	}else if(statusIcon==3||statusIcon==4){
		router_push_name('realname_enterprise', { stepIndex: 2 }, false)
	}else if(statusIcon==5 || statusIcon==6){
		router_push_name('realname_enterprise', { stepIndex: 3 }, false)
	}else if(statusIcon==7||statusIcon==8){
		router_push_name('realname_enterprise', { stepIndex: 4 }, false)
	}else{
		handleMessage('认证状态错误')
	}
})
defineExpose({
	getDetails
});
</script>
<style lang="scss" scoped >
@import "@/styles/general/layout/labelbox.scss";

.detailsbox {
	width: 100%;
	height: 100%;
	position: relative;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #333333;
	padding: 0 34px;
	padding-top: 34px;

	.ovbox {
		height: calc(100% - 60px);
		overflow: auto;
	}

	.fotbox {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 60px;
		background: #FFFFFF;
		box-shadow: 0px -1px 8px 0px rgba(219, 219, 219, 0.5);
	}

	.imgbox {
		padding: 20px 0;
		background: #FFFFFF;
		border: 1px solid #DEDEDE;
	}

	// 隐藏滚动条
	::-webkit-scrollbar {
		width: 0 !important
	}
}</style>